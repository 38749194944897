import "./src/styles/global.css";
import React from "react";
import "@fontsource/poppins"; // Default weight (400)
import "@fontsource/poppins/300.css"; // Light
import "@fontsource/poppins/700.css"; // Bold

import { CalendlyProvider } from "./src/context/ModalsContext";
require("@fontsource/dm-sans");

export const wrapRootElement = ({ element }) => (
  <CalendlyProvider>{element}</CalendlyProvider>
);
